<template>
  <v-sheet>
    <v-img
      :src="heroJpg"
      :lazy-src="heroLazy"
      :min-height="mdAndUp ? 500 : '80dvh'"
      max-height="calc(90dvh - var(--v-layout-top))"
      cover
    >
      <template #sources>
        <source :srcset="heroWebp">
      </template>

      <v-container
        class="max-md:py-10"
        :class="{ 'fill-height': mdAndUp }"
      >
        <v-row>
          <v-col
            md="14"
            lg="14"
            xl="12"
            class="space-y-5"
          >
            <u-text class="text-heading-h2">
              <slot name="title" />
            </u-text>

            <u-text class="text-body-lg">
              <slot name="subtitle" />
            </u-text>

            <slot name="actions" />

            <trustpilot-widget
              schema-type="Organization"
              style-height="90px"
              style-width="150px"
              template-id="53aa8807dec7e10d38f59f32"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-img>

    <v-sheet
      color="surface-bg-accent"
      class="py-8"
    >
      <slot name="info" />
    </v-sheet>

    <slot />

    <template v-if="faq">
      <v-sheet class="py-8">
        <v-container>
          <v-row justify-md="center">
            <v-col
              cols="24"
              md="18"
              lg="15"
              xl="14"
              xxl="13"
            >
              <u-text
                class="font-galano text-28 xl:text-36 mb-4"
                text="Frequently Asked Questions"
              />

              <storyblok-component
                v-for="(blok, key) in faq"
                :key="key"
                :blok="blok"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </template>
  </v-sheet>
</template>

<script setup lang="ts">
defineProps<{
  faq?: Record<any, any>[]
  heroJpg?: string
  heroWebp?: string
  heroLazy?: string
}>()

const { mdAndUp } = useDisplay()
</script>
